// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-team-tsx": () => import("./../../../src/pages/our-team.tsx" /* webpackChunkName: "component---src-pages-our-team-tsx" */),
  "component---src-pages-project-showcase-tsx": () => import("./../../../src/pages/project-showcase.tsx" /* webpackChunkName: "component---src-pages-project-showcase-tsx" */)
}

